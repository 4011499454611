

























































































































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { DateTime } from 'luxon'

import Confirmation from '@/components/modals/Confirmation.vue'
import DateTimeInput from '@/components/_uikit/controls/DateTimeInput.vue'
import MastersAccessControl from '@/components/_uikit/controls/MastersAccessControl.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import SwitchInput from '@/components/_uikit/controls/SwitchInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import AuthModule from '@/store/modules/auth'
import {
  ExerciseAccessLevel,
  ExerciseLargeResource,
  ExerciseStore,
  ExerciseType,
  ProgramMonthLargeResource,
  TaskStoreRequest,
} from '@/store/types'
// utils
import { convertSecondsToHoursMinutes, formatDate } from '@/utils/functions'

@Component({
  components: {
    Confirmation,
    DateTimeInput,
    MastersAccessControl,
    Select,
    SwitchInput,
    TextInput,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class ExerciseForm extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ default: null })
  private exercise!: ExerciseLargeResource

  @Prop({ default: null })
  private month!: ProgramMonthLargeResource

  @Prop({ default: false })
  private isCurrentMasterGroupPlus!: boolean

  @Prop({ default: null })
  private subjectId!: number

  @Prop({ default: () => ([]) })
  private tasks!: TaskStoreRequest[]

  @Prop({ default: false })
  private isTemplate!: boolean

  // Общедоступное задание. Не может быть индивидуальным
  @Prop({ default: false })
  private isPublic!: boolean

  // Индивидуальное задание. Не может быть общедоступным
  @Prop({ default: false })
  private isPersonal!: boolean

  @Prop({ default: false })
  private isProbe!: boolean

  // Дата выдачи д/з наступила?
  @Prop({ default: false })
  private isSpendingAt!: boolean

  @Prop({ default: false })
  private autoCheck!: boolean

  // Индекс активного таба (для пробников)
  @Prop({ default: 0 })
  private selectedTaskIndex!: number

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private timeMask = [/\d/, /\d/, ':', /[0-5]/, /\d/]
  private timePattern = new RegExp(/^\d{2}:[0-5]\d$/m)

  private formAutoCheck = false

  // Можно ли ставить дедлайн раньше текущего времени или даты выдачи
  private isDeadlineEarlierNow = process.env.VUE_APP_IS_DEADLINE_EARLIER_NOW === '1'

  private form: ExerciseStore = {
    accessLevel: ExerciseAccessLevel.PUBLIC,
    autoCheck: this.formAutoCheck,
    avgExecutionSeconds: '' as unknown as number,
    deadlineAt: this.isTemplate ? undefined : '',
    forAllMasters: false,
    isProbe: false,
    spendingAt: '',
    isTimerEnabled: false,
    title: '',
  }

  private isIndividual = false

  private get subjects () {
    return DictionaryModule.subjects
  }

  // Проверка на наличие ключей во всех тасках, кроме тестов
  private get isKey(): boolean {
    if (!this.tasks || !this.tasks.length) return true
    return this.tasks.filter(task => task.type !== ExerciseType.TEST).every(task => task.answerMediaIds.length)
  }

  // Таймштамп дедлайна дз
  private get deadlineExerciseTimestamp(): number {
    return this.exercise ? (DateTime.fromSQL(this.exercise.deadlineAt, { zone: 'Europe/Moscow' }) as any).ts : 0
  }

  // Доступ для редактирования дедлайна дз
  // если до дедлайна больше часа, редактирование доступно, иначе - нет
  private get isChangeDeadlineExercise() {
    return this.deadlineExerciseTimestamp === 0 ? false : this.deadlineExerciseTimestamp - Date.now() <= 3600000
  }

  private mounted () {
    this.formAutoCheck = this.autoCheck
    if (this.isPersonal) {
      this.form.accessLevel = ExerciseAccessLevel.PERSONAL
      this.isIndividual = true
    }

    if (this.exercise) {
      this.form = {
        accessLevel: this.exercise.accessLevel.value as ExerciseAccessLevel,
        autoCheck: this.exercise.autoCheck,
        avgExecutionSeconds: convertSecondsToHoursMinutes(this.exercise.avgExecutionSeconds) as unknown as number,
        deadlineAt: this.exercise.deadlineAt ? formatDate(this.exercise.deadlineAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone) : this.exercise.deadlineAt,
        forAllMasters: this.exercise.forAllMasters,
        isProbe: this.exercise.isProbe,
        masterIds: this.exercise.masters.map(master => master.id),
        spendingAt: this.exercise.spendingAt ? formatDate(this.exercise.spendingAt, 'yyyy-MM-dd HH:mm:ss', this.isLocalTimezone) : this.exercise.spendingAt,
        subjectId: +this.exercise.subject.value,
        isTimerEnabled: this.exercise.isTimerEnabled,
        title: this.exercise.title,
      }
      this.handleChangeAutoCheck(this.exercise.autoCheck)
      this.isIndividual = this.form.accessLevel === ExerciseAccessLevel.PERSONAL || false
      this.handleChangeIsProbe(this.exercise.isProbe)
    } else if (this.subjectId) {
      this.form.subjectId = this.subjectId
    } else if (this.month) {
      this.form.subjectId = +this.month.course.subject.value
    }

    this.$bus.$on('manual-save-exercise-base', this.handleSubmit)
  }

  private destroyed() {
    this.$bus.$off('manual-save-exercise-base', this.handleSubmit as any)
  }

  private handleChangeIndividual (value: boolean) {
    this.form.accessLevel = value ? ExerciseAccessLevel.PERSONAL : ExerciseAccessLevel.PUBLIC
  }

  private handleChangeAutoCheck(value: boolean) {
    this.$bus.$emit('changeAutoCheck', value)
  }

  private handleChangeIsProbe (value: boolean) {
    if (!value && this.isProbe !== value && this.tasks.length > 1) {
      this.confirm.open(
        'Смена типа задания',
        'При изменении типа с пробника на обычное, созданные задания, начиная со второго, будут неактивны и недоступны мастеру для выполнения. Для сохранения изменений необходимо нажать кнопку “Сохранить” на основе задания.<br><br>Для восстановления заданий необходимо сменить тип на&nbsp;пробник.',
        {
          buttonConfirmText: 'Продолжить',
          skin: 'secondary',
        },
      )
        .then(() => {
          setTimeout(() => {
            this.form.isProbe = false
            this.$emit('update:isProbe', false)
          })
        })
        .catch(() => {
          setTimeout(() => {
            this.form.isProbe = true
            this.$emit('update:isProbe', true)
          })
        })
    } else {
      this.form.isProbe = value
      this.$emit('update:isProbe', value)
    }
  }

  private handleSaveExercise(e: Event) {
    this.exercise ? this.$bus.$emit(`save-exercise-container-${this.selectedTaskIndex}`) : this.handleSubmit({ autoCheck: this.form.autoCheck || false, confirm: e })
  }

  @Debounce(300)
  @Bind
  private handleSubmit(save: { autoCheck: boolean, confirm: boolean | Event }) {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {

          if (save.autoCheck && !this.isKey) {
            this.notifyError('Необходимо добавить ключи для проверки ко всем заданиям за исключением теста.')
            return
          }

          // показывать модалку подтверждения, если:
          // 1 - домашка редактируется, а не создается
          // 2 - дата выдачи уже наступила
          // 3 - домашка не находится в банке дз
          // 4 - если нажата кнопка сохранить в этом компоненте
          if (this.exercise && this.isSpendingAt && this.$route.name !== 'manager.bank.exercises.item.edit' && typeof save.confirm !== 'boolean') {
            this.confirm.open(
              'Сохранение изменений',
              'Вы уверены, что хотите сохранить внесенные изменения в домашнее задание? После сохранения изменения будут доступны всем пользователям с доступом к заданию.',
              {
                buttonConfirmText: 'Сохранить',
                skin: 'secondary',
              },
            )
              .then(() => {
                this.submit(form)
              })
              .catch(() => {return})
          } else {
            this.submit(form)
          }
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private submit(form: any) {
    if (this.month && formatDate(this.form.spendingAt, 'MM') !== formatDate(this.month.date, 'MM')) {
      this.confirm.open(
        'Конфликт даты',
        `Дата выдачи данного задания <span class="text-body-3 secondary--text">${formatDate(this.form.spendingAt, 'dd MMM yyyy, HH:ss', this.isLocalTimezone)}</span>
                           не соответствует заполняемому месяцу <span class="text-body-3 secondary--text">${formatDate(this.month.date, 'LLLL')}</span> в программе курса.
                           Вы действительно хотите сохранить с текущей датой? После сохранения задание отобразится в месяце, указанном в форме.`,
        {
          buttonConfirmText: 'Сохранить',
          skin: 'secondary',
        },
      )
        .then(() => {
          this.$emit('submit', this.form)
          requestAnimationFrame(() => (form.reset()))
        })
        .catch(() => {return})
    } else {
      this.$emit('submit', this.form)
      requestAnimationFrame(() => (form.reset()))
    }
  }

  private handleChangeSubject(id: number) {
    this.$bus.$emit('handleChangeSubmit', id)
  }

  @Watch('autoCheck')
  private watchAutoCheck() {
    this.formAutoCheck = this.autoCheck
  }

  @Watch('formAutoCheck')
  private watchFormAutoCheck() {
    this.form.autoCheck = this.formAutoCheck
  }
}
