


















import { MetaInfo } from 'vue-meta'
import { Component, Mixins } from 'vue-property-decorator'

import ExerciseForm from '@/components/forms/exercise/ExerciseForm.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import MentorExercisesModule from '@/store/modules/mentor/exercises'
import { ExerciseLargeResource, ExerciseStore } from '@/store/types'
import { convertDateToMSK, convertHoursMinutesToSeconds } from '@/utils/functions'
import AuthModule from '@/store/modules/auth'

@Component({
  components: { ExerciseForm },
})
export default class ExercisesNew extends Mixins(MasterGroupMixin, NotifyMixin) {
  private get breadcrumbs () {
    return [{ name: 'Домашняя работа', path: '/manager/education/exercises' }]
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private created () {
    this.syncMasterGroup()
  }

  private handleSubmit (form: ExerciseStore) {
    const formSubmit = {
      ...form,
      avgExecutionSeconds: convertHoursMinutesToSeconds(form.avgExecutionSeconds as unknown as string),
      deadlineAt: form.deadlineAt ? convertDateToMSK(form.deadlineAt, this.isLocalTimezone) : form.deadlineAt,
      masterIds: form.forAllMasters ? [] : form.masterIds,
      spendingAt: form.spendingAt ? convertDateToMSK(form.spendingAt, this.isLocalTimezone) : form.spendingAt,
    }

    MentorExercisesModule.saveExercise({
      masterGroupID: this.currentMasterGroupID,
      params: formSubmit,
    })
      .then((response: ExerciseLargeResource) => {
        this.notifySuccess('Основа задания сохранена')
        this.$router.push({
          name: 'manager.education.exercises.item.edit',
          params: {
            exerciseUUID: response.uuid,
            groupID: this.currentMasterGroupID.toString(),
          },
        })
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Создание задания',
    }
  }
}
